<template>
  <span class="edit-date-comments-modal" v-if="canEdit">
    <b-modal
      dialog-class="modal-md"
      :id="modalId"
      @ok.prevent="onSave"
      @shown="init()"
    >
      <template v-slot:modal-title>
        <b v-if="item">
          <i class="fas fa-calendar-xmark"></i>&nbsp;
          Modifier la présence de {{ item.individual.firstAndLastName() }} pour le
          {{ item.date.startDateTime | dateToString('ddd LL HH:mm') }}
        </b>
      </template>
      <div v-if="error" class="errors-message">
        {{ error }}
      </div>
      <b-row>
        <b-col>
          <b-form-group
            label-for="comments"
            label="Commentaires"
            description="max. 100 caractères"
          >
            <b-form-input type="text" v-model="comments" id="comments" maxlength="100">
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row><b-col>
        <div class="help-text2">Cliquez sur le statut souhaité.</div>
      </b-col></b-row>
      <b-row>
        <b-col v-for="elt of presenceChoices" :key="elt.id">
          <a href @click.prevent="doSave(elt.id)" class="choice-btn" :class="{ active: elt.id === presence, }">
            <i :class="getIcon(elt.id)"></i>
            <div>{{ elt.name }}</div>
          </a>
        </b-col>
      </b-row>
      <template #modal-footer>
        &nbsp;
      </template>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeChoice } from '@/types/base'
import { AgendaPresence, makeAgendaDatePresence } from '@/types/agenda'

export default {
  name: 'set-agenda-presence-modal',
  components: { },
  mixins: [BackendMixin],
  props: {
    modalId: String,
    item: Object,
  },
  data() {
    return {
      presence: 0,
      error: '',
      comments: '',
    }
  },
  computed: {
    canEdit() {
      return this.hasPerm('agenda.add_activitydatepresence')
    },
    presenceChoices() {
      return [
        makeChoice({ id: AgendaPresence.Presence, name: 'Présent', }),
        makeChoice({ id: AgendaPresence.Missing, name: 'Absent', }),
        makeChoice({ id: AgendaPresence.Unknown, name: 'Je ne sais pas', })
      ]
    },
  },
  watch: {
    editEvent: function() {
      this.init()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async init() {
      if (this.item) {
        this.presence = this.item.agenda ? this.item.agenda.presence : 0
        this.comments = this.item.agenda ? this.item.agenda.comments : ''
      } else {
        this.comments = ''
        this.presence = 0
      }
    },
    getIcon(presence) {
      if (presence === AgendaPresence.Presence) {
        return 'fa fa-circle-check'
      } else if (presence === AgendaPresence.Missing) {
        return 'fa fa-circle-exclamation'
      } else if (presence === AgendaPresence.Unknown) {
        return 'fa fa-circle-question'
      } else {
        return 'fa fa-exclamation'
      }
    },
    async doSave(presence) {
      this.error = ''
      if (this.item) {
        let url = '/agenda/api/agenda-date-presences/' + this.item.model + '/' + this.item.id + '/'
        let data = {
          individual: this.item.individual.id,
          date: this.item.date.id,
          presence: presence,
          comments: this.comments.substring(0, 100),
        }
        let backendApi = new BackendApi('post', url)
        try {
          const resp = await backendApi.callApi(data)
          await this.addSuccess('La modification a été prise en compte')
          this.$emit('saved', makeAgendaDatePresence(resp.data))
          this.$bvModal.hide(this.modalId)
        } catch (err) {
          this.error = this.getErrorText(err)
        }
      }
    },
    isValid() {
      return this.item
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
 .fa {
    font-size: 32px;
  }
  .fa-circle {
    color: #eee;
  }
  .fa-circle-check {
    color: #5fad5f;
  }
  .fa-circle-exclamation {
    color: #d9376d;
  }
  .fa-exclamation {
    color: #efcb64;
  }
  .fa-circle-question {
    color: #49c1da;
  }
  .errors-message {
    background: #f1b0b7;
    padding: 5px 10px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .choice-btn {
    text-align: center;
    display: block;
    border: solid 1px #ccc;
    padding: 20px 5px;
    vertical-align: center;
    height: 120px;
    border-radius: 8px;
  }
  .choice-btn:hover {
    text-decoration: none;
    background: #eee;
  }
  .choice-btn.active {
    background: #f1f2b6;
  }
 .choice-btn.active:hover {
    background: #e2e3ad;
  }
</style>
