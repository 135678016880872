<template>
  <a href @click.prevent="onClick()">
    <i class="icon no-print" :class="icon"></i>
    <span class="hide-here">{{ pdfIcon }}</span>
    <div style="font-size: 11px" v-if="comments">{{ comments}}</div>
  </a>
</template>

<script>
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'AgendaPresenceIndicator',
  mixins: [BackendMixin],
  components: {
  },
  props: {
    model: String,
    id: Number,
    date: Object,
    individual: Object,
    map: Map,
  },
  data() {
    return {
    }
  },
  computed: {
    key() {
      return '' + this.individual.id + '#' + this.date.id
    },
    presence() {
      return this.map.get(this.key) || null
    },
    icon() {
      const item = this.presence
      if (item) {
        return item.getIcon()
      } else {
        return 'fa fa-circle'
      }
    },
    pdfIcon() {
      const item = this.presence
      if (item) {
        return item.getPdfIcon()
      } else {
        return ''
      }
    },
    comments() {
      const item = this.presence
      if (item) {
        return item.comments
      } else {
        return ''
      }
    },
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    onClick() {
      this.$emit(
        'click',
        {
          model: this.model,
          id: this.id,
          individual: this.individual,
          date: this.date,
          agenda: this.presence,
        }
      )
    },
  },
}
</script>

<style scoped>
  .icon {
    font-size: 32px;
  }
  .icon.fa-circle {
    color: #eee;
  }
  .icon.fa-circle-check {
    color: #5fad5f;
  }
  .icon.fa-circle-exclamation {
    color: #d9376d;
  }
  .icon.fa-exclamation {
    color: #efcb64;
  }
  .icon.fa-circle-question {
    color: #49c1da;
  }
</style>
